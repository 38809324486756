<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        st-account-validation
        d-flex
        flex-row-fluid flex-column
        justify-content-center
        mx-auto
      "
    >
      <div class="card">
        <div class="card-header border-0 pt-14 pb-11">
          <b-img :src="logoImage" width="25" />
        </div>
        <div class="card-body d-flex flex-column pt-0 pb-14">
          <h2 class="card-title font-weight-bold">
            {{ $t("AUTH.INVALID_LINK.TITLE") }}
          </h2>
          <div>
            <i18n
              path="AUTH.INVALID_LINK.MESSAGE"
              tag="label"
              class="font-weight-normal pb-7"
            >
            </i18n>
          </div>
          <div class="mt-10">
            <i18n path="AUTH.INVALID_LINK.REGISTER_MESSAGE" tag="span">
              <b-link @click="goToRegister" class="font-weight-bold">
                {{ $t("AUTH.INVALID_LINK.REGISTER_LINK") }}
              </b-link>
            </i18n>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-end py-7 py-lg-0 mb-10"
    >
      <a href="#" class="text-primary font-weight-bold font-size-h6">{{
        $t("AUTH.GENERAL.PRIVACY")
      }}</a>
      <a href="#" class="text-primary ml-10 font-weight-bold font-size-h6">{{
        $t("AUTH.GENERAL.LEGAL")
      }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "InvalidLink",
  data() {
    return {};
  },
  computed: {
    logoImage() {
      return `${process.env.BASE_URL}media/st-logo-stema.svg`;
    },
  },
  methods: {
    goToRegister() {
      this.$router.replace({ name: 'signup' });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/auth/account-validation.scss";
</style>
